import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from 'react-bootstrap'
import { Section } from '../components/Section'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section className="section" pt='100px' pb='70px'>
      <Container>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
